import { ref, watch, computed,onMounted  } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function contactHandler() {
  // Use toast
  const toast = useToast()

  const refBusinessListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nombre y apellido', sortable: false },
    // { key: 'lastname', label: 'lastname', sortable: false },
    { key: 'phone', label: 'Teléfono', sortable: false },
    { key: 'email', label: 'Correo electrónico', sortable: false },
    { key: 'business_name', label: 'Empresa', sortable: false },
    { key: 'updated_at', label: 'Actualizado', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalBusiness = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const serviceInterest = ref([])

  const refetchData = () => {
    refBusinessListTable.value.refresh()
  }

  watch([currentPage, searchQuery], () => {
    refetchData()
  })

  const fetchContacts = (ctx, callback) => {
    store
      .dispatch('app-contacts/fetchContacts', {
        searchText: searchQuery.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalBusiness.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchServiceInterest = () => {
    store
      .dispatch('app-business/fetchServiceInterest')
      .then(response => {
        serviceInterest.value = response.data.payload.results
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  onMounted(() => {
    // fetchServiceInterest()
  })

  // console.log(props,"handler")
  // const businessObjectLocal = ref(JSON.parse(JSON.stringify(props.businessData.value)))
  // const resetBusinessObjectLocal = () => {
  //   businessObjectLocal.value = JSON.parse(JSON.stringify(props.businessData.value))
  // }
  // watch(props.businessData, () => {
  //   resetBusinessObjectLocal()
  // })

  //
  // const onSubmit = () => {
  //   const taskData = JSON.parse(JSON.stringify(taskLocal))
  //
  //   // * If event has id => Edit Event
  //   // Emit event for add/update event
  //   if (props.task.value.id) emit('update-task', taskData.value)
  //   else emit('add-task', taskData.value)
  //
  //   // Close sidebar
  //   emit('update:is-task-handler-sidebar-active', false)
  // }

  return {
    // businessObjectLocal,
    // resetBusinessObjectLocal,
    fetchContacts,
    // serviceInterest,
    tableColumns,
    perPage,
    currentPage,
    totalBusiness,
    searchQuery,
    refBusinessListTable,
    refetchData,
  }
}
