<template>
  <b-card
    no-body
  >
    <contact-sidebar
      v-model="isContactHandlerSidebarActive"
      :contact="contactObject"
      :clear-data="resetData"
      @add-contact="addContact"
      @update-contact="updateContact"
    />
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>Total de contactos</h3> <small> (Hay {{ totalBusiness }} contactos)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="8"
        >
          <b-col
            cols="6"
            md="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar por nombre,apellido,email o empresa"
            />

            <div class="">
              <flat-pickr
                v-model="filterDate"
                class="form-control mr-1"
                :config="{ enableTime: false, dateFormat: 'Y-m',locale: locale}"
              />
            </div>
            <b-button
              v-can-permissions="'Descargar contactos'"
              variant="info"
              class="ml-1 mr-1"
              @click="downloadContacts"
            >
              Excel
            </b-button>
            <b-button
              v-can-permissions="'Creacion de contactos'"
              variant="primary"
              @click="isContactHandlerSidebarActive = true"
            >
              Crear
            </b-button>
          </b-col>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refBusinessListTable"
      :items="fetchContacts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Name -->
      <template #cell(name)="data">
        <!--        <span class="text-nowrap">-->
        <!--          {{ data.item.name }} {{ data.item.lastname }}-->
        <!--        </span>-->

        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(`${data.item.name} ${data.item.lastname}`)"
              variant="light-primary"
            />
          </template>
          <span>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="editContact(data.item)"
            >
              {{ data.item.name }} {{ data.item.lastname }}
            </b-link>
          </span>

          <small class="text-muted">{{ data.item.position }}</small>
        </b-media>
      </template>

      <!-- Column:Phone -->
      <template #cell(mobilePhone)="data">
        <span class="text-nowrap">
          {{ data.item.mobilePhone }}
        </span>
      </template>

      <!-- Column: Email -->
      <template #cell(status_business)="data">
        <span class="text-nowrap">
          {{ data.item.email }}
        </span>
      </template>

      <!-- Column: Business Name -->
      <template #cell(business_name)="data">
        <span class="text-wrap">
          {{ data.item.business_name }}
        </span>
      </template>

      <!-- Column:Created At -->
      <template #cell(created_at)="data">
        <span class="text-nowrap">
          {{ data.item.created_at }}
        </span>
      </template>

      <!-- Column:Updated At -->
      <template #cell(updated_at)="data">
        <span class="text-nowrap">
          {{ data.item.updated_at }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <span v-can-permissions="'Cambiar vendedor'">
            <feather-icon
              :id="`form-change-seller-to-contact-${data.item.id}`"
              size="16"
              icon="UsersIcon"
              class="cursor-pointer mr-1"
              @click="showPopupChangeSeller(data.item)"
            />
            <b-tooltip
              title="Cambiar vendedor"
              class="cursor-pointer"
              :target="`form-change-seller-to-contact-${data.item.id}`"
            />
            <b-popover
              :ref="`form-change-seller-to-contact-${data.item.id}`"
              :target="`form-change-seller-to-contact-${data.item.id}`"
              triggers="click"
              placement="lefttop"
            >
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Selecciona un vendedor"
                      label-for="setting-type-discount"
                    >
                      <v-select
                        v-model="selectedNewSeller"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sellersList"
                        label="name"
                        :reduce="option => option.id"
                        input-id="setting-type-discount"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    class="d-flex justify-content-between mt-1"
                  >
                    <b-button
                      variant="outline-primary"
                      @click="updateSellerContact(data.item.id)"
                    >
                      Cambiar
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="() => {$refs[`form-change-seller-to-contact-${data.item.id}`].$emit('close');selectedNewSeller = null;}"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-popover>
          </span>

          <feather-icon
            :id="`invoice-row-${data.item.id}-pen-icon`"
            icon="FileTextIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'quotations-create', params: {currency : 'pen'},query: {contactId: data.item.id} })"
          />
          <b-tooltip
            title="Cotización soles"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-pen-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-usd-icon`"
            icon="FileIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="$router.push({ name: 'quotations-create', params: {currency : 'usd'}, query: {contactId: data.item.id} })"
          />
          <b-tooltip
            title="Cotización dolares"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-usd-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-show-icon`"
            icon="EyeIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'contacts-detail', params: {id : data.item.id}})"
          />
          <b-tooltip
            title="Ver detalle"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-show-icon`"
          />

          <span v-can-permissions="'Editar contactos'">
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="editContact(data.item)"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-send-icon`"
            />
          </span>

          <feather-icon
            :id="`contact-row-${data.item.id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class=" cursor-pointer"
          />
          <b-tooltip
            title="Eliminar"
            :target="`contact-row-${data.item.id}-delete-icon`"
          />
          <b-popover
            :target="`contact-row-${data.item.id}-delete-icon`"
            variant="danger"
            triggers="focus"
            placement="left"
          >
            <template #title>
              <span>Eliminar contacto: {{ data.item.name }} {{ data.item.lastname }} </span>
            </template>

            <div class="">
              <p>
                Al eliminar este contacto se eliminará toda su información relacionada como: COTIZACIONES,VENTAS,NOTAS,
                TESTIMONIOS, GUÍAS.
              </p>
            </div>

            <b-button
              variant="outline-danger"
              class="mr-1"
              size="sm"
              @click="deleteContact(data.item)"
            >
              <span class="text-nowrap">Eliminar</span>
            </b-button>
          </b-popover>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBusiness"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip, BLink, BPopover, BForm, BFormGroup,
} from 'bootstrap-vue'
import store from '@/store'
import contactStoreModule from '@/views/dramox/accounts/contacts/contactStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import contactHandler from '@/views/dramox/accounts/contacts/contactHandler'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import ContactSidebar from '@/views/dramox/accounts/contacts/sidebar/contactSidebar.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import vSelect from 'vue-select'

export default {
  name: 'HomeBusiness',
  components: {
    ContactSidebar,
    flatPickr,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BLink,
    BPopover,
    BForm,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      value: '',
      formatted: '',
      selected: '',
      locale: SpanishLocale,
    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    updateSellerContact(contactId) {
      if (!this.selectedNewSeller) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debe seleccionar un vendedor',
            icon: 'AlertTriangleIcon',
            text: '',
            variant: 'danger',
          },
        })
        return
      }
      this.$store.dispatch('app-contacts/updateSellerContact', {
        contactId,
        body: { sellerId: this.selectedNewSeller },
      })
        .then(() => {
          this.$root.$emit('bv::hide::popover', `form-change-seller-to-contact-${contactId}`)
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Vendedor actualizado',
              icon: 'CheckIcon',
              text: '',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al actualizar vendedor',
              icon: 'AlertTriangleIcon',
              text: '',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-contacts'
    const isContactHandlerSidebarActive = ref(false)
    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, contactStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({
      title,
      icon,
      variant,
    }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const sellersList = ref([])
    const selectedNewSeller = ref(null)

    const filterDate = ref(null)

    const blankBusinessData = {
      name: '',
      lastname: '',
      mobilePhone: '',
      email: '',
      emailAdditional: '',
      position: '',
      businessId: '',
      photo: null,
    }

    const contactObject = ref(JSON.parse(JSON.stringify(blankBusinessData)))
    const resetData = () => {
      contactObject.value = JSON.parse(JSON.stringify(blankBusinessData))
    }

    const {
      // serviceInterest,
      tableColumns,
      currentPage,
      totalBusiness,
      perPage,
      searchQuery,
      refBusinessListTable,
      refetchData,
      fetchContacts,
    } = contactHandler()

    const editContact = item => {
      isContactHandlerSidebarActive.value = true
      contactObject.value = item
    }

    const addContact = val => {
      const formData = new FormData()
      formData.append('name', val.name)
      formData.append('lastname', val.lastname)
      formData.append('mobilePhone', val.mobilePhone)
      formData.append('email', val.email)
      formData.append('emailAdditional', val.emailAdditional)
      formData.append('position', val.position)
      formData.append('businessId', val.businessId)

      store.dispatch('app-contacts/addContact', { body: formData })
        .then(() => {
          showNotification({
            title: 'Creado con éxito',
            icon: 'CheckCircleIcon',
            variant: 'success',
          })
          router.push({ name: 'quotations' })
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({
            title: messageError,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    }

    const updateContact = val => {
      const formData = new FormData()
      formData.append('name', val.name)
      formData.append('lastname', val.lastname)
      formData.append('mobilePhone', val.mobilePhone)
      formData.append('email', val.email)
      formData.append('emailAdditional', val.emailAdditional)
      formData.append('position', val.position)
      formData.append('businessId', val.businessId)
      formData.append('photo', val.photo)

      store.dispatch('app-contacts/updateContact', {
        id: val.id,
        body: formData,
      })
        .then(() => {
          showNotification({
            title: 'Actualizado con éxito',
            icon: 'CheckCircleIcon',
            variant: 'success',
          })
          refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({
            title: messageError,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    }

    const deleteContact = val => {
      store.dispatch('app-contacts/deleteContact', { id: val.id })
        .then(() => {
          showNotification({
            title: 'Eliminado con éxito',
            icon: 'CheckCircleIcon',
            variant: 'success',
          })
          refetchData()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({
            title: messageError,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    }
    const downloadContacts = () => {
      store.dispatch('app-contacts/downloadContact', { date: filterDate.value })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'contactos.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          const messageError = error.response.data.error.errors[0]
          showNotification({
            title: messageError,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          })
        })
    }

    store.dispatch('app-contacts/fetchSellers')
      .then(response => {
        sellersList.value = response.data.payload.results
      })
      .catch(() => {
        showNotification({
          title: 'Oops!, ocurrió un error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })
      })

    const showPopupChangeSeller = data => {
      selectedNewSeller.value = data.sellerId !== 3 ? data.sellerId : null
    }

    // console.log(serviceInterest,businessObject.value)
    return {
      fetchContacts,
      tableColumns,
      currentPage,
      totalBusiness,
      searchQuery,
      perPage,
      refBusinessListTable,
      refetchData,

      isContactHandlerSidebarActive,
      contactObject,
      resetData,

      avatarText,

      editContact,
      addContact,
      updateContact,
      deleteContact,

      downloadContacts,
      filterDate,

      sellersList,
      // updateSellerContact,
      selectedNewSeller,
      showPopupChangeSeller,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
